import ahoy from 'ahoy.js';
import 'bootstrap3/dist/js/bootstrap';
import 'waypoints/lib/jquery.waypoints';

window.ahoy = ahoy;

$(document).ready(function ($) {
  function wp (number) {
    $('.total-number-1').text = number;
  }
  window.wp = wp;

  const nb = document.querySelector('.navbar-default');
  console.log(nb);
  var waypoint = new Waypoint({
    element : nb,
    handler : function (direction) {
      if (direction === 'down') {
        nb.className = nb.className + ' stuck';
      } else {
        nb.className = nb.className.sub(' stuck', '');
      }
      console.log('Scrolled to waypoint!');
    },
    offset  : 20,
  });
});

$(function () {
  'use strict';

  /* ==========================================================================
   Sub Form
   litebox
   ========================================================================== */

  $('.litebox-tour').magnificPopup({
    type         : 'inline',
    mainClass    : 'mfp-fade',
    removalDelay : 160,
    inline       : {
      markup :
        '<div class="text-center">' +
        '<div class="mfp-close"></div>' +
        '<video id="hub_sdp_trailer" class="video-js vjs-default-skin"' +
        'controls autoplay preload="auto" width="600" height="600"' +
        'data-setup="{"example_option":true}">' +
        '<source src="http://dv42xeigbp7m6.cloudfront.net/videos/getting-started-demo.mp4" type="video/mp4" />' +
        '<p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>' +
        '</video>' +
        '</div>',
    },
  });

  $('.litebox-concierge').magnificPopup({
    type : 'iframe',
  });

  $('.litebox-hero').magnificPopup({
    type         : 'inline',
    mainClass    : 'mfp-fade',
    removalDelay : 160,
    inline       : {
      markup :
        '<div class="text-center">' +
        '<div class="mfp-close"></div>' +
        '<video id="hub_sdp_trailer" class="video-js vjs-default-skin"' +
        'controls autoplay preload="auto" width="600" height="600"' +
        'data-setup="{"example_option":true}">' +
        '<source src="http://dv42xeigbp7m6.cloudfront.net/videos/hub_sdp_1080p.mp4" type="video/mp4" />' +
        '<p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>' +
        '</video>' +
        '</div>',
    },
  });

  $('.litebox-signup').magnificPopup({
    type      : 'inline',
    mainClass : 'mfp-fade',
    inline    : {
      markup :
        '<div class="white-popup">' +
        '<div class="text-center">' +
        '<div class="mfp-close"></div>' +
        '<h1 class="green">Fantastic!</h1>' +
        '</div>' +
        '<p>You\'re almost there.  In order to proceed with your account setup please check your email and click the <strong class="green">"Activate Account"</strong> link.</p>' +
        '</div>',
    },
  });

  $('.open-popup-link').magnificPopup({
    type     : 'inline',
    midClick : true,
  });
});
